import React from 'react'
import Layout from '@components/Layout'
import { useTranslation} from 'gatsby-plugin-react-i18next';

const NotFoundPage = ({ location }) => {
  const { t, i18n } = useTranslation();
  return (
    <Layout location={location} t={t} i18n={i18n}>
    <div style={{ height: '100vh', width: '80%', margin: 'auto' }}>
      <h1 style={{ fontSize: '3rem', padding: '3rem' }}>404 NOT FOUND</h1>
    </div>
  </Layout>
  )
}

export default NotFoundPage
